<template>
    <div id="projects">
        <vue-topprogress ref="topProgress" :color="$barColor" :height="$barHeight"></vue-topprogress>
        <section id="inner-headline">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <h2 class="pageTitle">{{ job.title }}</h2>
                    </div>
                </div>
            </div>
        </section>
        <section id="content">
            <div class="container">
                <div class="row" id="cform">
                    <div class="col-md-6">
						<div class="text-center">
							<img v-if="job.image" :src="$uploadsURL+'jobs/'+job.image" alt="" style="max-width: 256px; border: none;">
							<h1>{{ job.title }}</h1>
							<h3 style="color: #171717;">Location - {{ job.location }}</h3>
						</div>
						<div v-html="job.description"></div>
                    </div>
                    <div class="col-md-6">
                        <h3>Apply Now!</h3>
                        <p>Please fill out the form below and click "Submit." We will get back to you as soon as possible!</p>
                        <div class="contact-form">
                            <form id="job-form" @submit.prevent="apply4job" class="contact">
                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="form-group has-feedback">
                                            <label>First Name*</label>
                                            <input v-model.trim="first_name" name="first_name" type="text" class="form-control" required>
                                            <i class="fa fa-user form-control-feedback"></i>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="form-group has-feedback">
                                            <label>Last Name*</label>
                                            <input v-model.trim="last_name" name="last_name" type="text" class="form-control" required>
                                            <i class="fa fa-user form-control-feedback"></i>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="form-group has-feedback">
                                            <label>Email*</label>
                                            <input v-model.trim="email" name="email" type="email" class="form-control" required>
                                            <i class="fa fa-envelope form-control-feedback"></i>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="form-group has-feedback">
                                            <label>Phone*</label>
                                            <input v-model.trim="phone" name="phone" type="text" class="form-control" required>
                                            <i class="fa fa-building-o form-control-feedback"></i>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
									<div class="col-md-6">
                                        <div class="form-group">
                                            <label>Resume*</label>
											<input id="resume" type="file" required/>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
									<div class="col-md-6">
										<div class="form-group">
											<input type="submit" value="Submit" id="submit" class="submit btn btn-default">
										</div>
									</div>
								</div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script type="text/javascript" scoped>
export default{
	name: "JobView",
	data(){
		return {
			first_name: null,
			last_name: null,
			email: null,
			phone: null,
			job: {
				id: "",
				title: "",
				image: "",
				location: "",
				description: "",
			},
		};
	},
	mounted(){
		this.$refs.topProgress.start();
		this.$axios({
			method: "get",
			url: this.$apiURL+"get_job/"+this.$route.params.id,
		}).then((res) => {
			if(!res.data){
				this.$router.push("/jobs");
			}
			this.job = res.data;
			this.$refs.topProgress.done();
		});
	},
	methods: {
		apply4job(){
			this.$refs.topProgress.start();
			var formData = new FormData();
			formData.append("job_id", this.job.id);
			formData.append("first_name", this.first_name);
			formData.append("last_name", this.last_name);
			formData.append("email", this.email);
			formData.append("phone", this.phone);
			var resume = document.querySelector("#resume").files[0];
			formData.append("resume", resume);
			this.$axios({
				method: "post",
				url: this.$apiURL+"apply4job",
				headers: {
					"Content-Type": "multipart/form-data",
				},
				data: formData,
			}).then(() => {
				this.$refs.topProgress.done();
				this.$toasted.success("Your application has been submitted successfully, we will contact you shortly.");
				this.first_name = null;
				this.last_name = null;
				this.email = null;
				this.phone = null;
			});
		},
    }
}
</script>

<style type="text/css" scoped>
.row .row{
	margin-bottom: 0px;
}
#resume{
	border: none;
	margin: 0px;
	padding: 0px;
}
</style>                            